<template>
  <fki-container
    fixed
    column
    top="131px"
    left="0"
    width="100%"
    class="fki-services-page"
  >
    <fki-container-header
      :color="page.color"
      min-height="100px"
      :title="page.body"
      contact-target="production"
      has-contact
      @openContact="openContact"
    />
    <fki-production-services
      :slides="page.slides"
      :services="services"
    />

    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="showContactPopup"
    overlay
    persistent
    has-padding
    color="#424242"
    @close="closeContact()"
  >
    <fki-contact-form @isLoading="isLoading" />
  </fki-container-popup>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKIContainer, FKIContainerHeader, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIProductionServices } from '@/components/FKIServices'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContactForm } from '@/components/FKIContact'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Production',
  components: {
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-production-services': FKIProductionServices,
    'fki-container-popup': FKIContainerPopup,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-form': FKIContactForm
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      showContactPopup: false,
      showEmailResponsePopup: false,
      messageReponse: '',
      page: { color: 'blue', body: 'ON LOCATION - DATA, DEVICES, & DAILIES', slides: [] },
      services: []
    }
  },
  async created () {
    await this.getPage()
    await this.getServices(this.page.categoryIdentifier)
  },
  methods: {
    async checkParams () {
      await this.getPage()
      await this.getServices(this.page.categoryIdentifier)
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/page/s/production')
        this.page = result
        this.page.slides.forEach(element => {
          element.src = element.src.replace('image/', '')
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getServices (categoryIdentifier) {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/services/c/' + categoryIdentifier)
        this.services = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    closeContact () {
      this.showContactPopup = false
    },
    openContact (item) {
      this.showContactPopup = true
    },
    closeEmailResponse () {
      this.showEmailResponsePopup = false
    },
    openEmailResponse (item) {
      this.showEmailResponsePopup = true
    },
    isLoading (b) {
      console.log('isLoading')
      console.log(b)
      this.$emit('isLoading', b)
    }
  }
}
</script>
<style lang="scss" scoped>
  .fki-services-page {
    bottom:0;
  }
</style>
